.section-about {
  background-color: #f1f1f1;
  padding-left: 20px;
  min-height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  font-family: 'Open Sans' !important;
  font-weight: 400;
}

body {
  background-color: #f8f8fa;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

body,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td,
label {
  font-style: normal;
  font-size: 13px;
  line-height: 20.0px;
  text-transform: none;
  letter-spacing: 0px;
  color: #000000;
}


span {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20.0px;
  text-transform: none;
  letter-spacing: 0px;
  /* font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif; */
}

div {
  font-style: normal;
  font-weight: 350;
  font-size: 13px;
  line-height: 20.0px;
  text-transform: none;
  letter-spacing: 0px;
  /* font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif; */
}

h4 {
  font-size: 13px;
  font-weight: bold;
  color: #000000;
}


input {
  font-size: 13px !important;
  /* color: #565656 !important; */
}

button {
  border-radius: 3px !important;
  transition: all 0.3s ease-in-out !important;
}

button:hover {
  transform: translateY(1px);
}

.myCustum-card {
  transition: all 0.3s ease-in-out;
  width: 235px;
  height: 340px;
}




.myCustum-card:hover {
  transform: translateY(-1px);
  /* box-shadow: 8px 8px 8px -6px #000 !important; */
  box-shadow: 0px 0px 10px 2px #00000050 !important;
  border: 1px solid #fe696a70;
}


.text-white {
  color: #fff !important;
}


@media (min-width: 992px) {

  .my-container {
    max-width: calc(100vw - 10vw);
    margin: 0px auto;
    padding: 0px;
  }


  .create-prestation-container {
    max-width: calc(100vw - 40vw);
    padding: 0px;
  }

}


@media (max-width: 991px) {
  .my-container {
    max-width: calc(100vw - 10vw);
    margin: 0px auto;
  }
}

@media (max-width: 1200px) {
  .my-container {
    padding: 0px 20px;
  }

  .create-prestation-container {
    padding: 0px 20px;
  }

}


/* Ant design classe */

.ant-menu-horizontal {
  display: flex !important;
  align-items: center !important;
}

.ant-scroll-number-only-unit {
  color: #fff !important;
}

.ant-form-item-label {
  padding: 0 !important;

}

.ant-form-item {
  margin-bottom: 10px !important;
}

.ant-picker-dropdown {
  z-index: 1061 !important;
}

.spinner-custom {
  -webkit-animation: spinner .5s linear infinite;
  animation: spinner .5s linear infinite;
  border-color: #000 rgba(0, 0, 0, .1) rgba(0, 0, 0, .1);
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  display: inline-block;
}


.my-spinner {
  /* Spinner size and color */
  width: 1.3rem;
  height: 1.3rem;
  border-top-color: #444;
  border-left-color: #444;

  /* Additional spinner styles */
  animation: spinner 400ms linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
}

/* Animation styles */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.notification-in-app {
  background-color: #ff000010;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.notification-in-app:hover {
  background-color: burlywood;
}

.read {
  font-weight: normal;
  color: #00000090;
}

.my-spinner.spinner-white {
  border-top-color: #fff;
  border-left-color: #fff;
}


.my-spinner.spinner-red {
  border-top-color: red;
  border-left-color: red;
}

.my-shadow {
  box-shadow: rgb(64 75 90 / 20%) 0px 0px 1px 0px,
    rgb(64 75 90 / 28%) 0px 2px 1px 0px;
}

.my-bg-light {
  background-color: #F3FBFF;
}


.page-title {
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  color: #f3722c;
}

.ant-input {
  border-radius: 6px !important;
  background-color: #fbfcfd !important;
  color: #343741 !important;
}


.ant-select-selection-item {
  /* line-height: 22px !important; */
}