.home {
    height: 100vh;
    margin: 0px auto;
}

.home-header {
    width: 100%;
}

.home-heros {
    margin: 0px auto;
    margin-bottom: 30px;
}


.home-left-menu a {
    color: #000 !important;
    transition: all 0.3s ease-in-out !important;
}

.home-left-menu a:hover,
.home-left-menu a.active {
    color: aquamarine !important;
}

.home-top-categorie-menu {
    margin: 0px auto !important;
    width: 85% !important;
    border: none !important;
    display: flex !important;
    justify-content: center !important;
}

.home-top-categorie-root {
    position: sticky;
    top: 50px;
    z-index: 5;
    background: #fff;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 2px 1px #ccc;
}


.home-content {
    margin-top: 50px;
}

.home-top-bar {
    max-height: 50px;
    min-height: 50px;
    background: #fff;
}

.home-become-offer {
    background: #24C6DC;
    background: -webkit-linear-gradient(to right, #514A9D, #24C6DC);
    background: linear-gradient(to right, #514A9D, #24C6DC);
}


.swiper-slide {
    padding: 0px;
    margin: 0px;
    margin-left: 5px;
}

.swiper-button-next {
    /* background: #fff; */
    border-bottom-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 95%;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto 0px;
}

.swiper-button-prev {
    /* background: #fff; */
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 95%;
    left: 0;
    bottom: 0;
    top: 0;
    margin: auto 0px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 14px;
    color: #fff;
    padding: 10px;
    font-weight: bold;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.461);
}


.section-filter-container {
    position: sticky;
    top: 150px;
    height: 100%;
}

.categorie-item {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.categorie-item:hover,
.categorie-item.active {
    color: #ffd000 !important;
    transition: all 0.3s ease-in-out;
    text-decoration: underline;
    text-decoration-color: #ffd000;
    font-weight: bold;
}

.item-menu {
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    color: #44444e;
}

.item-menu:hover {
    color: #fe696a;
}

.custom-card {
    transition: 0.4s all ease-in-out;
}

.custom-card:hover {
    transform: scale3d(1.01, 1.01, 1.01);
}


.text-truncate-two {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
}

.text-truncate-one {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
}

.text-truncate-tree {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
}

.text-truncate-four {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
}


.see-all-categories {
    text-decoration: underline;
}

.see-all-categories:hover {
    color: #fe696a !important;
    font-weight: bold;
    text-decoration: none;
}

.menu-categorie {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}


.mega-menu-categorie {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    opacity: 1;
    color: #000;
    background: #fff;
    border: 1px solid #fe696a;
    left: 0;
    width: 100%;
    transform: translateY(10px);
    animation: animation-mega-menu 0.5s alternate;
}


.facebook-social,
.youtube-social,
.instagram-social,
.twitter-social {
    cursor: "pointer"
}

.facebook-social:hover {
    color: blue
}

.youtube-social:hover {
    color: #fe696a;
}

.twitter-social:hover {
    color: deepskyblue;
}

.instagram-social:hover {
    color: blue;
}



.rate-item {
    color: #ffd000;

}

.rate-container {
    transition: 0.2s all ease-in-out;
}

.rate-container:hover {
    background-color: #4d4211;
    cursor: pointer;
    border-radius: 5px;

}


.rate-item svg {
    font-size: 18px;
}

.rate-item.active {
    color: #3cb9c3;
}


.categorie-container {
    padding: 10px;
}

.categorie-container:hover,
.categorie-container.current {
    background-color: #fe696a;
    color: #fff;
    cursor: auto;
    transition: all 0.3s ease-in-out;
}


.categorie-container:hover .mega-menu-categorie {
    display: block;
}

.close-filtre-item-button:hover {
    background: #fe696a;
    color: #fff;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.input-search-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0px auto;
}

/* .input-search-container:hover {
    box-shadow: 0px 0px 10px 5px #fe696a50;
} */

/* .input-search {
    outline: none;
    height: 100%;
    width: 100%;
    background-color: none;
    border: none;
    
} */

.become-prestataire-btn {
    padding: 10px 20px;
    border: 1px solid #fff;
    background-color: #00000040;
    margin-top: 20px;
    font-size: 15px;
    color: #fff;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
}

.become-prestataire-btn:hover {
    background-color: #fff;
    color: #000;
}

.card-item {
    min-width: 200px;
    margin-top: 10px;
}




@media (min-width: 1201px) {

    .toggle-android-top-bar {
        display: none;
        opacity: 0;
        transition: all 0.5s ease-in-out;
    }

}

@media (max-width: 1200px) {
    .android-header {
        visibility: hidden;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 0px;
        background: #FFF;
        overflow-y: auto;
        height: 100vh;
        transition: all 0.5s ease-in-out;
    }

    .android-header.open {
        width: 70%;
        opacity: 1;
        visibility: visible;
        transition: all 0.5s ease-in-out;
    }


    .toggle-android-top-bar {
        display: block;
        opacity: 1;
        border-bottom: 1px solid #ccc;
    }

    .desktop-header {
        display: none;
    }

    .android-header .logo-AF {
        margin: 10px 0px;
    }

    .android-header .input-search-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 10px 0px;
    }

    .android-header .input-field {
        margin: 10px 0px;
    }

    .android-header .categorie-menu {
        min-width: 300px;
        margin: 20px 0px;
    }

    .android-header .categorie-mega-menu-content-android {
        display: none;
        opacity: 0;
        transition: all 0.5s ease-in-out;

    }

    .android-header .categorie-mega-menu-content-android.active {
        display: block;
        opacity: 1;
    }

    .android-header .close-btn {
        position: absolute;
        top: 5px;
        right: 0px;
        font-size: 30px;
        border: 1px solid #44444e;
        background-color: #44444e;
        color: orange;
        padding: 10px;
        font-weight: bold;
        cursor: pointer;
    }
}


@keyframes open-animation {
    0% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
}

@media (min-width: 1201px) {

    .desktop-header {
        display: block;
        position: sticky;
        z-index: 10;
        top: 0;
        left: 0;
    }

    .android-header {
        display: none;
    }

    .categorie-mega-menu-container {
        position: relative;
    }

    .categorie-mega-menu-content-desktop {
        visibility: hidden;
        position: absolute;
        opacity: 0;
        top: 100%;
        background-color: #fff;
        border: 1px solid #ccc;
        border-top: 4px solid #cba600;
        padding: 10px;
        transition: all 0.4s ease-in-out;
        width: 1100px;
    }


    .categorie-mega-menu-container:hover .categorie-mega-menu-content-desktop {
        visibility: visible;
        opacity: 1;
    }


}

ul {
    list-style: none;
}


.banner-input-container {
    display: flex;
    justify-content: center;
    height: 40px;
}


.banner-input-container>button {
    background: #44444e;
    padding: 10px;
    color: #fff;
    border: none;
    outline: none;
    font-weight: bold;
}

.my-blur-4 {
    filter: blur(4px);
}

.my-blur-3 {
    filter: blur(3px);
}

.my-blur-6 {
    filter: blur(6px);
}

/* .categorie-banner{
    background-image: url('bg.jpg'); */