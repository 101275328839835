.admin-menu-item-active {
    background-color: #F3722c30;
    border: 1px solid #ccc;
    color: #FFF;
}

.admin-left-menu-item span {
    color: #565656;
}

.admin-menu-item-active span {
    color: #000;
}

.admin-left-menu-item {
    padding: 8px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    margin-top: 2px;
}

.admin-left-menu-item:hover {
    background-color: #00000015;
}

.admin-change-profil {
    /* background: #05668d; */
    max-width: 800px;
    padding: 10px;
    border-radius: 10px;
    margin: 10px auto;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #ccc;
    /* box-shadow: 0px 0px 2px 1px #05668d; */
}